import { useEffect, useState } from 'react';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Box, Container, Stack, Typography, Divider, Button, Collapse } from '@mui/material';
// components
import { useSettingsContext } from 'src/components/settings';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';

import { fNumber } from "src/utils/format-number";

import { useDispatch, useSelector } from 'src/store';
import { edit } from 'src/store/reducers/auth';

import { claimAchievement } from 'src/api/rewards';

// ----------------------------------------------------------------------

const StyledStackComponent = styled(Stack)(({ theme }) => ({
    width: "100%",
    padding: 16,
    borderRadius: 16,
    gap: 12,
    backgroundColor: "#fff",
    boxShadow: "0 .25rem .5rem rgba(0,0,0,.06),0 .125rem 0 rgba(0,0,0,.05)",
}));

const AchievementRewardsView = () => {
    const settings = useSettingsContext();
    const isMobile = useResponsive('down', 'sm');
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);

    const userWagerAmount: number = (user?.betAmount || 0) - (user?.achievementAmount || 0);

    const handleClaimAchievement = async () => {
        const response = await claimAchievement();

        if (response.status) {
            dispatch(edit(!response.data ? {} : response.data));
        }
    };

    return (
        <Container maxWidth={settings.themeStretch ? false : 'xl'} sx={{ pb: 2 }}>
            <Stack
                sx={{
                    p: 1,
                    width: 1,
                    mt: { xs: 1, md: 5 }
                }}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography variant="h5" my={1}>VIP Rewards</Typography>
            </Stack>

            <Divider sx={{ mb: 1 }} />

            <Stack gap={2}>
                <Typography variant="h6">(1 Bonus = 1000 Wager)</Typography>

                <Stack
                    direction={isMobile ? "column" : "row"}
                    gap={1.5}
                >
                    <StyledStackComponent>
                        <Typography variant="h5" textTransform="uppercase">Before</Typography>

                        <Box component="span" fontSize={18} fontWeight={700}>Wager : <Box component="span" color="#2065D1">{fNumber(userWagerAmount || 0)}</Box></Box>
                        <Box component="span" fontSize={18} fontWeight={700}>Bonus : <Box component="span" color="#71ca63">0</Box></Box>
                    </StyledStackComponent>

                    <StyledStackComponent>
                        <Typography variant="h5" textTransform="uppercase">After</Typography>

                        <Box component="span" fontSize={18} fontWeight={700}>Wager : <Box component="span" color="#2065D1">{fNumber((userWagerAmount || 0) % 1000)}</Box></Box>
                        <Box component="span" fontSize={18} fontWeight={700}>Bonus : <Box component="span" color="#71ca63">{Math.floor((userWagerAmount || 0) / 1000)}</Box></Box>
                    </StyledStackComponent>
                </Stack>

                <Button
                    variant="contained"
                    color="primary"
                    sx={{
                        fontWeight: "500",
                        '&:hover': { backgroundColor: (theme) => alpha(theme.palette.primary.main, 1), color: 'white' }
                    }}
                    disabled={userWagerAmount < 1000}
                    onClick={handleClaimAchievement}
                >Claim</Button>
            </Stack>
        </Container >
    );
}

export default AchievementRewardsView;