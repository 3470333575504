// @mui
import { Container, Stack, Typography, Divider } from '@mui/material';
// components
import { useSettingsContext } from 'src/components/settings';

// ----------------------------------------------------------------------

const AboutUsView = () => {
    const settings = useSettingsContext();

    return (
        <Container maxWidth={settings.themeStretch ? false : 'xl'}>
            <Stack
                direction="column"
                gap={0.5}
                sx={{
                    p: 1,
                    mt: 5,
                    mb: 1,
                    width: 1,
                    borderRadius: 2,
                    backgroundColor: "#fff",
                    boxShadow: "0 .25rem .5rem rgba(0,0,0,.06),0 .125rem 0 rgba(0,0,0,.05)"
                }}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography variant="h5" my={1}>About us</Typography>
                </Stack>

                <Divider sx={{ mb: 1 }} />

                <Typography sx={{ mt: 1 }}>Happy to see you on Bstake! Our team has applied all the experience gained over more than 20 years in the gaming industry to create an innovative and truly exciting online casino, with a special attention to detail and current trends, as well as putting the interests of our players first.</Typography>

                <Typography sx={{ mt: 1 }}>Thanks to innovative technologies you will not waste your time counting every penny — we know exactly when our players require a personal reward or a cashback and it is always on time!</Typography>

                <Typography sx={{ mt: 1 }}>Bstake hosts over 4000 games developed by over 40 providers — a wide selection of classic and modern slots with the Buy Bonus feature (we did not forget that!), Live casino games, and, of course, Megaways! For the best navigation and player experience, we have developed a simple and convenient search engine with many additional filters.</Typography>

                <Typography sx={{ mt: 1 }}>Here we have all the modern trends combined — the most exciting games from the leading providers of the industry, a completely unique loyalty program and an intuitive interface with a minimalist design. And most importantly, on Bstake you can use the most popular cryptocurrencies without any restrictions or extra hassle.</Typography>

                <Typography sx={{ mt: 1 }}>Thank you for choosing us!</Typography>
                <Typography>Sincerely,</Typography>
                <Typography>Bstake Team.</Typography>
            </Stack>
        </Container >
    );
}

export default AboutUsView;