import { useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Container, Stack, Button } from '@mui/material';
// components
import { useSettingsContext } from 'src/components/settings';

import FinancialView from "./financial-view";
import GamingView from "./gaming-view";

// ----------------------------------------------------------------------

const TransactionView = () => {
  const settings = useSettingsContext();

  const [activeTab, setActiveTab] = useState("financial");

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <Stack
        direction="row"
        gap={0.5}
        sx={{
          p: 0.25,
          my: 2,
          width: 1,
          borderRadius: 1,
          bgcolor: (theme) => alpha(theme.palette.grey[500], 0.04),
          border: (theme) => `dashed 1px ${theme.palette.divider}`
        }}
      >
        <Button variant="outlined" sx={{ width: 1, ...(activeTab === "financial" && { backgroundColor: (theme) => alpha(theme.palette.grey[400], 0.8) }) }} onClick={() => setActiveTab("financial")}>Financial</Button>

        <Button variant="outlined" sx={{ width: 1, ...(activeTab === "gaming" && { backgroundColor: (theme) => alpha(theme.palette.grey[400], 0.8) }) }} onClick={() => setActiveTab("gaming")}>Gaming</Button>
      </Stack>

      <Stack
        direction="row"
        gap={0.5}
        sx={{
          p: 1,
          mb: 1,
          width: 1,
          borderRadius: 1,
          backgroundColor: "#fff",
          boxShadow: "0 .25rem .5rem rgba(0,0,0,.06),0 .125rem 0 rgba(0,0,0,.05)"
        }}
      >
        {activeTab === "financial" ? <FinancialView /> : <GamingView />}
      </Stack>
    </Container >
  );
}

export default TransactionView;