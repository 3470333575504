import { useEffect } from 'react';
// @mui
import { Box, Stack, Drawer, Divider } from '@mui/material';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// components
import { Logo } from 'src/components/logo';
import Scrollbar from 'src/components/scrollbar';
import { usePathname } from 'src/routes/hooks';
import { NavSectionVertical } from 'src/components/nav-section';
import { useSelector } from 'src/store';
//
import { NAV } from '../config-layout';
import { useNavData, useLockNavData } from './config-navigation';
import { NavToggleButton, NavUpgrade, NavFooter } from '../_common';

// ----------------------------------------------------------------------

type Props = {
  openNav: boolean;
  onCloseNav: VoidFunction;
};

export default function NavVertical({ openNav, onCloseNav }: Props) {
  const { isLoggedIn } = useSelector((state) => state.auth);

  const pathname = usePathname();

  const lgUp = useResponsive('up', 'lg');

  const navData = useNavData();
  const lockNavData = useLockNavData();

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Logo sx={{ mt: 3, ml: 4, mb: 1 }} />

      {isLoggedIn && <NavUpgrade />}

      {/* <Divider sx={{ mb: 1 }} /> */}

      <NavSectionVertical
        data={isLoggedIn ? navData : lockNavData}
        config={{
          currentRole: 'admin',
        }}
      />

      {isLoggedIn && <Divider />}

      {isLoggedIn && <NavFooter />}

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_VERTICAL },
        zIndex: 1201,
        backgroundColor: "#fff",
        boxShadow: "0 .5rem 1rem rgba(0,0,0,.15)"
        // backgroundColor: theme => theme.palette.background.default
      }}
    >
      <NavToggleButton />

      {lgUp ? (
        <Stack
          sx={{
            height: 1,
            position: 'fixed',
            width: NAV.W_VERTICAL,
            // borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          }}
        >
          {renderContent}
        </Stack>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.W_VERTICAL,
              backgroundColor: "#fff !important",
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
