import { useEffect, useCallback } from 'react';
// @mui
import Box from '@mui/material/Box';
import { enqueueSnackbar } from 'notistack';
import { io } from 'socket.io-client';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
// components
import { useSettingsContext } from 'src/components/settings';
import axios from 'src/utils/axios';
import { getNotifications } from 'src/api/notifications';
import { useDispatch, useSelector } from 'src/store';
import { setNotificationCount, receiveNewNotification } from 'src/store/reducers/notification';
import { edit } from 'src/store/reducers/auth';
import { API_PATH, SOCKET_URL } from 'src/config-global';
//
import Main from './main';
import Header from './header';
import Footer from './footer';
import NavMini from './nav-mini';
import NavVertical from './nav-vertical';
// import NavHorizontal from './nav-horizontal';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function DashboardLayout({ children }: Props) {
  const settings = useSettingsContext();

  const lgUp = useResponsive('up', 'lg');

  const nav = useBoolean();

  // const isHorizontal = settings.themeLayout === 'horizontal';

  const isMini = settings.themeLayout === 'mini';

  const renderNavMini = <NavMini />;

  // const renderHorizontal = <NavHorizontal />;

  const renderNavVertical = <NavVertical openNav={nav.value} onCloseNav={nav.onFalse} />;

  const dispatch = useDispatch();
  const { isLoggedIn, user } = useSelector((state) => state.auth);

  const checkAuthStatus = useCallback(async () => {
    const response = await axios.post(API_PATH.AUTH_CHECK);
    return dispatch(edit(!response ? {} : response.data));
  }, [dispatch]);

  useEffect(() => {
    const socket = io(`${SOCKET_URL}`);

    if (isLoggedIn) {
      socket.emit('newPlayer', user);

      socket.on('receiveNotification', (newNotificationData: any) => {
        enqueueSnackbar("New notification has arrived from admin", { variant: 'info' });

        dispatch(receiveNewNotification());
      });

      socket.on('checkBalance', () => {
        checkAuthStatus();
      });
    } else {
      socket.disconnect();
    }

    return () => {
      socket.off('receiveNotification');
      socket.off('checkBalance');
    };
  }, [isLoggedIn, user, dispatch, checkAuthStatus]);

  const getNotificationData = useCallback(async () => {
    const response: any = await getNotifications();

    if (response.status) {
      dispatch(setNotificationCount({ count: response.data.length || 0 }));
    }
  }, [dispatch]);

  useEffect(() => {
    if (isLoggedIn) {
      getNotificationData();
    }
  }, [getNotificationData, isLoggedIn]);

  // if (isHorizontal) {
  //   return (
  //     <>
  //       <Header onOpenNav={nav.onTrue} />

  //       {lgUp ? renderHorizontal : renderNavVertical}

  //       <Main>{children}</Main>
  //     </>
  //   );
  // }

  if (isMini) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />

        <Box
          sx={{
            minHeight: 1,
            display: 'flex',
            flexDirection: { xs: 'column', lg: 'row' },
            backgroundColor: "#ededee"
          }}
        >
          {lgUp ? renderNavMini : renderNavVertical}

          <Main>
            {children}

            <Footer />
          </Main>
        </Box>
      </>
    );
  }

  return (
    <>
      <Header onOpenNav={nav.onTrue} />

      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
          backgroundColor: "#ededee"
        }}
      >
        {renderNavVertical}

        <Main>
          {children}

          <Footer />
        </Main>
      </Box>
    </>
  );
}
