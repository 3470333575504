// @mui
import { alpha } from '@mui/material/styles';
import { Box, Stack, Avatar, Typography } from '@mui/material';
// components
import Label from 'src/components/label';
// store
import { useSelector } from 'src/store';

import Iconify from 'src/components/iconify';

import { fNumber } from "src/utils/format-number";
import { ASSETS_API } from 'src/config-global';

// ----------------------------------------------------------------------

export default function NavUpgrade() {
  const { user } = useSelector((state) => state.auth);

  return (
    <Stack
      sx={{
        px: 2,
        py: 5,
        pb: 2,
        textAlign: 'center',
      }}
    >
      <Stack alignItems="center">
        <Box sx={{ position: 'relative' }}>
          <Avatar src={user.avatar ? user.avatar : `${ASSETS_API}/assets/images/avatar/avatar_25.jpg`} alt={user?.name} sx={{ width: 48, height: 48 }} />
          <Label
            color="success"
            variant="filled"
            sx={{
              top: -6,
              px: 0.5,
              left: 40,
              height: 20,
              position: 'absolute',
              borderBottomLeftRadius: 2,
            }}
          >
            user
          </Label>
        </Box>

        <Stack spacing={0.5} sx={{ mt: 1.5, mb: 2 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.name}
          </Typography>

          <Typography variant="body2" noWrap sx={{ color: 'text.disabled' }}>
            {user?.email}
          </Typography>
        </Stack>

        <Stack
          direction="column"
          gap={1}
          sx={{ width: 1 }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              // ...bgGradient({
              //   direction: '135deg',
              //   startColor: alpha(theme.palette.primary.light, 0.2),
              //   endColor: alpha(theme.palette.primary.main, 0.2),
              // }),
              height: { md: 1 },
              width: 1,
              borderRadius: 1,
              position: 'relative',
              cursor: 'pointer',
              color: '#71ca63',
              backgroundColor: '#ecf8ea',
              '&:hover': {
                color: '#4471e3',
                backgroundColor: '#e6ecfb',
                // ...bgGradient({
                //   direction: '135deg',
                //   startColor: alpha(theme.palette.primary.light, 0.3),
                //   endColor: alpha(theme.palette.primary.main, 0.3),
                // })
              }
            }}
          >
            <Box sx={{ borderRadius: '50%', width: '24px', margin: 2, bgcolor: (colorTheme) => alpha(colorTheme.palette.grey[100], 1) }}>$</Box>
            <Typography>{fNumber(user.balance || 0)}</Typography>
            <Iconify
              icon='ic:baseline-plus'
              sx={{ m: 1, flexShrink: 0 }}
            />
          </Stack>

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              // ...bgGradient({
              //   direction: '135deg',
              //   startColor: alpha(theme.palette.secondary.light, 0.2),
              //   endColor: alpha(theme.palette.secondary.main, 0.2),
              // }),
              height: { md: 1 },
              width: 1,
              borderRadius: 1,
              position: 'relative',
              cursor: 'pointer',
              color: '#968cf7',
              backgroundColor: '#f1f0fe',
              '&:hover': {
                color: '#4471e3',
                backgroundColor: '#e6ecfb',
                // ...bgGradient({
                //   direction: '135deg',
                //   startColor: alpha(theme.palette.secondary.light, 0.3),
                //   endColor: alpha(theme.palette.secondary.main, 0.3),
                // })
              }
            }}
          >
            <Box sx={{ borderRadius: '50%', width: '24px', margin: 2, bgcolor: (colorTheme) => alpha(colorTheme.palette.grey[100], 1) }}>B</Box>
            <Typography>{fNumber((user?.bonus || 0) + (user?.matchBonus || 0))}</Typography>
            <Iconify
              icon='grommet-icons:form-next'
              sx={{ m: 1, flexShrink: 0 }}
            />
          </Stack>
        </Stack>

      </Stack>
    </Stack>
  );
}
