import { createSlice } from '@reduxjs/toolkit';

type initialStateType = {
    game: string;
};

const initialState: initialStateType = {
    game: ''
};

const search = createSlice({
    name: 'search',
    initialState,
    reducers: {
        set(state, action) {
            state.game = action.payload;
        },

        remove(state) {
            state.game = '';
        },
    },
});

export default search.reducer;

export const { set, remove } = search.actions;