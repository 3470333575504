import { createSlice } from '@reduxjs/toolkit';

type initialStateType = {
  name: string;
  url: string;
  status: boolean;
};

const initialState: initialStateType = {
  name: '',
  url: '',
  status: false
};

const casino = createSlice({
  name: 'casino',
  initialState,
  reducers: {
    open(state, action) {
      state.name = action.payload.name;
      state.url = action.payload.url;
      state.status = true;
    },

    close(state) {
      state.name = '';
      state.url = '';
      state.status = false;
    },
  },
});

export default casino.reducer;

export const { open, close } = casino.actions;
