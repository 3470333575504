import axios from 'src/utils/axios';
import { API_PATH } from "src/config-global";

// ----------------------------------------------------------------------

export const getTransactionHistory = async (payload: any) => {
  const URL = API_PATH.TRANSACTION_HISTORY_GET;
  const response = await axios.post(URL, payload);
  return response.data;
}

export const getBetHistory = async (payload: any) => {
  const URL = API_PATH.BET_HISTORY_GET;
  const response = await axios.post(URL, payload);
  return response.data;
}