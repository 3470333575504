import { isMobile as isMobileDevice } from 'react-device-detect';
import { Dialog, AppBar, Toolbar, IconButton, Typography, List, Box } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import { useResponsive } from 'src/hooks/use-responsive';

import { useDispatch, useSelector } from 'src/store';
import { edit } from 'src/store/reducers/auth';
import { close } from 'src/store/reducers/casino';
import axios from 'src/utils/axios';

import { API_PATH } from 'src/config-global';

import CloseButton from 'src/assets/close_button.png';

// type ModalProps = {
//     modalStatus: boolean;
//     gameName: string;
//     openGameURL: string;
//     onClose: () => void;
// };

// const GameScreen = memo(({ url }: { url: string }) => {
//     const [visible, setVisible] = useState(false);

//     useEffect(() => {
//         const timeout = setTimeout(() => {
//             setVisible(true);
//         }, 1000)
//         return () => clearTimeout(timeout);
//     }, []);

//     return <iframe
//         title="CasinoGame"
//         style={{ width: '100%', height: '100%', border: 'none' }}
//         src={url}
//     />
// })

const CasinoGame = () => {
    const dispatch = useDispatch();
    const isMobile = useResponsive('down', 'sm');

    const { name, url, status }: { name: string, url: string, status: boolean } = useSelector((state) => state.casino);

    const closeGameModal = async () => {
        dispatch(close());

        const response = await axios.post(API_PATH.AUTH_CHECK);
        dispatch(edit(!response.data ? {} : response.data));
    }

    return (
        <Dialog
            fullScreen={isMobile || isMobileDevice}
            className="game-modal-content"
            open={status}
            fullWidth
            sx={{
                '& .MuiBackdrop-root': {
                    background: "linear-gradient(123deg, rgb(50, 108, 247) 10%, rgb(255, 14, 230) 86%)",
                },
                '& .MuiDialog-paper': {
                    maxWidth: { md: "80%" },
                    borderRadius: { md: "6px" }
                }
            }}
        >
            {
                (isMobile || isMobileDevice) ?
                    <Box
                        component="img"
                        src={CloseButton}
                        height={30}
                        width={30}
                        sx={{ position: "absolute", top: "5px", right: "5px", zIndex: 100, cursor: "pointer", '&:hover': { filter: "blur(4px)" } }}
                        onClick={() => closeGameModal()}
                    />
                    :
                    <AppBar className="header-container" sx={{ position: "relative", px: "0px !important" }}>
                        <Toolbar sx={{ minHeight: "auto !important", pr: "0px !important" }}>
                            <Typography sx={{ flex: 1 }} variant="h6" component="div" fontWeight={900} fontStyle="italic">
                                {name}
                            </Typography>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={() => closeGameModal()}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
            }

            <List className='flex items-center h-full p-0 m-0' sx={{ display: 'flex', height: { xs: '100vh', md: 'calc(100vh - 64px)' }, padding: 0 }}>
                <iframe
                    title="CasinoGame"
                    sandbox="allow-scripts allow-same-origin"
                    style={{ width: '100%', height: '100%', border: 'none' }}
                    src={url}
                />
            </List>
        </Dialog>
    );
}

export default CasinoGame;
