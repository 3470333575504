import { createSlice } from '@reduxjs/toolkit';

type initialStateType = {
  name: string;
  editPage: string;
};

const initialState: initialStateType = {
  name: '',
  editPage: ''
};

const drawer = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    openDrawer(state, action) {
      state.name = action.payload;
      state.editPage = '';
    },

    closeDrawer(state) {
      state.name = '';
      state.editPage = '';
    },

    openEditPage(state, action) {
      state.editPage = action.payload;
    },

    closeEditPage(state) {
      state.editPage = '';
    },
  },
});

export default drawer.reducer;

export const { openDrawer, closeDrawer, openEditPage, closeEditPage } = drawer.actions;
