import axios from 'src/utils/axios';
import { API_PATH } from "src/config-global";

// ----------------------------------------------------------------------

export const getBonuses = async () => {
  const URL = API_PATH.BONUS_GET;
  const response = await axios.post(URL, {});
  return response.data;
}

export const getVipInformation = async () => {
  const URL = API_PATH.VIP_DATA_GET;
  const response = await axios.post(URL, {});
  return response.data;
}

export const claimAchievement = async () => {
  const URL = API_PATH.ACHIEVEMENT_CLAIM;
  const response = await axios.post(URL, {});
  return response.data;
}