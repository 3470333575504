// @mui
import { Box, Container, Stack, Typography } from '@mui/material';
// components
import { Logo } from 'src/components/logo';

// ----------------------------------------------------------------------


export default function Footer() {

    return (
        <Box sx={{ backgroundColor: "rgba(219,220,228,.5)", py: 7, mt: 3 }}>
            <Container maxWidth="lg">
                <Stack direction="column" justifyContent="center" alignItems="center" gap={2}>
                    <Logo />
                    {/* <Divider orientation='horizontal' flexItem /> */}
                    <Typography fontSize="14px" textAlign="center">© 2024 Bstake.com | All Rights Reserved.</Typography>

                    <Typography fontSize="14px" textAlign="center">1 BTC = $62,656.64</Typography>

                    <Typography fontSize="14px" textAlign="center" mt={2}>
                        Bstake is owned and operated by Large Rare N.V., registration number: 146353, registered address: Korporaalweg 15, Willemstad, Curaçao. Contact us at support@bstake.com.
                    </Typography>

                    <Stack direction={{ xs: "column", md: "row" }} alignItems="center" justifyContent="center" gap={0.5}>
                        <Stack direction="row" gap={0.5}>
                            <Typography fontSize={14} color="#8c8383">Support </Typography>
                            <Typography fontSize={14} color="#000000" sx={{ cursor: "pointer" }}>support@bstake.com </Typography>
                            <Typography fontSize={14} color="#8c8383">| </Typography>
                        </Stack>

                        <Stack direction="row" gap={0.5}>
                            <Typography fontSize={14} color="#8c8383">Partners </Typography>
                            <Typography fontSize={14} color="#000000" sx={{ cursor: "pointer" }}>partners@bstake.com </Typography>
                            <Typography fontSize={14} color="#8c8383">| </Typography>
                        </Stack>

                        <Stack direction="row" gap={0.5}>
                            <Typography fontSize={14} color="#8c8383">Press </Typography>
                            <Typography fontSize={14} color="#000000" sx={{ cursor: "pointer" }}>press@bstake.com </Typography>
                        </Stack>
                    </Stack>

                    <Box
                        component="img"
                        src="/assets/images/footer/payment-strip.png"
                        sx={{
                            backgroundSize: "100% 100%",
                            backgroundRepeat: "no-repeat"
                        }}
                    />
                </Stack>
            </Container>
        </Box>
    )
}
