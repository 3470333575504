// @mui
import { Box, Container, Stack, Typography, Divider } from '@mui/material';
// components
import Iconify from 'src/components/iconify/iconify';
import { useSettingsContext } from 'src/components/settings';

export { default as ProfileView } from "./profile";
export { default as PasswordChangeView } from "./password-change";

// ----------------------------------------------------------------------
type ViewProps = {
  setEditPage: (value: string) => void;
};

const AccountView = ({ setEditPage }: ViewProps) => {
  const settings = useSettingsContext();

  const itemList = [
    {
      title: "My Profile",
      text: "Edit your personal information",
      icon: "iconoir:profile-circle",
      value: "profile",
      component: <Box />
    },
    {
      title: "Verification",
      text: "Verify your personal information",
      icon: "tdesign:secured",
      value: "verification",
      component: <Box />
    },
    {
      title: "Password",
      text: "Change your password",
      icon: "ic:round-password",
      value: "password",
      component: <Box />
    },
    {
      title: "Notification",
      text: "Edit your communication channel",
      icon: "solar:notification-unread-lines-linear",
      value: "notification",
      component: <Box />
    },
  ];

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <Stack
        direction="column"
        gap={0.5}
        sx={{
          p: 1,
          mt: 5,
          mb: 1,
          width: 1,
          borderRadius: 2,
          backgroundColor: "#fff",
          boxShadow: "0 .25rem .5rem rgba(0,0,0,.06),0 .125rem 0 rgba(0,0,0,.05)"
          // bgcolor: (theme) => alpha(theme.palette.grey[500], 0.04),
          // border: (theme) => `dashed 1px ${theme.palette.divider}`,
        }}
      >
        {itemList.map((item, index) => <Box key={index}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              cursor: "pointer",
              borderRadius: 1,
              '&:hover': {
                backgroundColor: "#e6ecfb",
                color: "#4471e3"
                // backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.16),
                //  color: 'primary.darker' 
              }
            }}
            onClick={() => { setEditPage(item.value) }}
          >
            <Box p={2}>
              <Iconify icon={item.icon} width={24} m={0.5} />
            </Box>
            <Stack direction="column" gap={1} flexGrow={1}>
              <Typography variant='body1'>{item.title}</Typography>
              <Typography variant='body2'>{item.text}</Typography>
            </Stack>
            <Iconify
              icon='grommet-icons:form-next'
              sx={{ m: 2, flexShrink: 0 }}
            />
          </Stack>
          <Box>
            <Divider sx={{ ml: 8 }} />
          </Box>
        </Box>)}
      </Stack>
    </Container>
  );
}

export default AccountView;