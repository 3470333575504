// @mui
import { Box, Container, Stack, Typography, Divider } from '@mui/material';
// components
import Iconify from 'src/components/iconify/iconify';
import { useSettingsContext } from 'src/components/settings';

export { default as BonusesView } from "./bonuses";
export { default as VIPRewardsView } from "./vip-rewards";
export { default as AchievementRewardsView } from "./achievement-rewards";

// ----------------------------------------------------------------------
type ViewProps = {
  setEditPage: (value: string) => void;
};

const RewardsView = ({ setEditPage }: ViewProps) => {
  const settings = useSettingsContext();

  const itemList = [
    {
      title: "Bonuses",
      text: "Your rewards and gifts",
      icon: "arcticons:rewards",
      value: "bonuses",
      component: <Box />
    },
    {
      title: "Promotions",
      text: "Play with more excitement",
      icon: "codicon:gift",
      value: "promotions",
      component: <Box />
    },
    {
      title: "VIP Rewards",
      text: "Your rewards according to VIP",
      icon: "arcticons:rewards",
      value: "vip",
      component: <Box />
    },
    {
      title: "Achievement Rewards",
      text: "Your rewards according to wager",
      icon: "arcticons:rewards",
      value: "achievement",
      component: <Box />
    }
  ];

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <Stack
        direction="column"
        gap={0.5}
        sx={{
          p: 1,
          mt: 5,
          mb: 1,
          width: 1,
          borderRadius: 2,
          backgroundColor: "#fff",
          boxShadow: "0 .25rem .5rem rgba(0,0,0,.06),0 .125rem 0 rgba(0,0,0,.05)"
        }}
      >
        {itemList.map((item, index) => <Box key={index}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              cursor: "pointer",
              borderRadius: 1,
              '&:hover': {
                backgroundColor: "#e6ecfb",
                color: "#4471e3"
              }
            }}
            onClick={() => { setEditPage(item.value) }}
          >
            <Box p={2}>
              <Iconify icon={item.icon} width={24} m={0.5} />
            </Box>
            <Stack direction="column" gap={1} flexGrow={1}>
              <Typography variant='body1'>{item.title}</Typography>
              <Typography variant='body2'>{item.text}</Typography>
            </Stack>
            <Iconify
              icon='grommet-icons:form-next'
              sx={{ m: 2, flexShrink: 0 }}
            />
          </Stack>
          <Box>
            <Divider sx={{ ml: 8 }} />
          </Box>
        </Box>)}
      </Stack>
    </Container>
  );
}

export default RewardsView;