import { useState, useEffect, useCallback } from 'react';
import dayjs, { Dayjs } from 'dayjs';
// @mui
import { Box, Stack, Typography, Divider, Pagination, Alert } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import Scrollbar from 'src/components/scrollbar/scrollbar';

import { fDateTime } from 'src/utils/format-time';
import { toCurrency } from 'src/utils/to-currency';
import { getTransactionHistory } from "src/api/cashier";


// ----------------------------------------------------------------------

const FinancialView = () => {
  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs(new Date().setDate(1)));
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs(new Date()));
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(0);
  const [data, setData] = useState<any[]>([]);

  const getResult = useCallback(async () => {
    const response = await getTransactionHistory({ startDate: startDate?.toDate(), endDate: endDate?.toDate(), page: pageNumber });

    if (response.data) {
      setData(response.data);
      setPageCount(response.count);
    } else {
      setData([]);
      setPageCount(1);
    }
  }, [startDate, endDate, pageNumber]);

  useEffect(() => {
    const startDateValue = startDate?.toDate().valueOf();
    const endDateValue = endDate?.toDate().valueOf();
    if (startDateValue && endDateValue && startDateValue > endDateValue) {
      setEndDate(startDate);
    }

    setPageNumber(1);
  }, [startDate, endDate]);

  useEffect(() => {
    getResult();
  }, [pageNumber, getResult]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box width={1}>
        <Stack
          direction="row"
          gap={1}
          width={1}
          mb={2}
        >
          <DatePicker
            label="Start Date"
            value={startDate}
            minDate={dayjs('2024-01-01')}
            onChange={(newDate) => setStartDate(newDate)}
            sx={{ width: 1 }}
          />

          <DatePicker
            label="End Date"
            value={endDate}
            minDate={dayjs(startDate)}
            onChange={(newDate) => setEndDate(newDate)}
            sx={{ width: 1 }}
          />
        </Stack>

        <Divider />

        <Scrollbar>
          {data.length > 0 ?
            data.map((item, index) => <Box key={index}>
              <Stack direction="row" justifyContent="space-between" alignItems="center" py={2} px={1}>
                <Typography width={1}>{fDateTime(item.createdAt)}</Typography>
                <Typography width={1} align="center">{toCurrency(item.amount)}</Typography>
                <Typography width={1}>{item.type}</Typography>
              </Stack>
              <Divider />
            </Box>) : <Alert severity="success" sx={{ mb: 3, mt: 1 }}>
              There is no data <strong>Select date</strong>
            </Alert>
          }
        </Scrollbar>

        {pageCount > 1 && <Pagination count={pageCount}
          size="small"
          page={pageNumber}
          onChange={(event, value: number) => setPageNumber(value)}
          sx={{ display: "flex", justifyContent: "end", my: 2 }}
        />}
      </Box>
    </LocalizationProvider>
  );
}

export default FinancialView;