import { createSlice } from '@reduxjs/toolkit';

type initialStateType = {
  count: number;
};

const initialState: initialStateType = {
  count: 0
};

const notification = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotificationCount(state, action) {
      state.count = action.payload.count;
    },

    receiveNewNotification(state) {
      state.count += 1;
    },

    checkedNotification(state) {
      state.count -= 1;
    },
  },
});

export default notification.reducer;

export const { setNotificationCount, receiveNewNotification, checkedNotification } = notification.actions;
