import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'src/store';
import { PATH_AFTER_LOGIN } from 'src/config-global';
import { GuardProps } from 'src/types';

const GuestGuard = ({ children }: GuardProps) => {
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((state) => state.auth);

  useEffect(() => {
    if (isLoggedIn) {
      navigate(PATH_AFTER_LOGIN, { replace: true });
    }
  }, [isLoggedIn, navigate]);

  return children;
};

export default GuestGuard;
