import { useState } from 'react';
// @mui
import { useTheme, styled, alpha } from '@mui/material/styles';
import { Stack, AppBar, Toolbar, IconButton, Button, Box, TextField, InputAdornment } from '@mui/material';
// theme
import { bgBlur } from 'src/theme/css';
// hooks
import { useRouter, usePathname } from 'src/routes/hooks';
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
import { paths } from 'src/routes/paths';
// components
import { Logo } from 'src/components/logo';
import SvgColor from 'src/components/svg-color';
import Iconify from 'src/components/iconify/iconify';
import { useSettingsContext } from 'src/components/settings';

import { useDispatch, useSelector } from 'src/store';
import { set, remove } from 'src/store/reducers/search';
//
import { HEADER, NAV } from '../config-layout';

// ----------------------------------------------------------------------

const MenuButton = styled(Button)(({ theme }) => ({
  px: 16,
  borderRadius: 32,
  backgroundColor: theme.palette.background.default,
  color: "#b1b1b1",
  '&:hover, &.active': {
    color: "#4471e3",
    boxShadow: "0 4px 8px rgba(0,0,0,.06),0 2px 0 rgba(0,0,0,.05)",
    backgroundColor: theme.palette.background.default,
  }
}));

type Props = {
  onOpenNav?: VoidFunction;
};

export default function Header({ onOpenNav }: Props) {
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { name } = useSelector((state) => state.drawer);

  const dispatch = useDispatch();
  const theme = useTheme();
  const router = useRouter();
  const pathName = usePathname();

  const settings = useSettingsContext();

  const isNavHorizontal = settings.themeLayout === 'horizontal';
  const isNavMini = settings.themeLayout === 'mini';

  const lgUp = useResponsive('up', 'lg');
  const isMobile = useResponsive('down', 'sm');

  const offset = useOffSetTop(HEADER.H_DESKTOP);

  const offsetTop = offset && !isNavHorizontal;

  const [isSearch, setIsSearch] = useState(false);
  const [searchGame, setSearchGame] = useState('');

  const handleCloseSearch = () => {
    setIsSearch(false);
    setSearchGame('');
    dispatch(remove());
  }

  const handleSearchGame = (event: any) => {
    setSearchGame(event.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      dispatch(set(searchGame));
    }
  };

  const renderContent = (
    <>
      {lgUp && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

      {!lgUp && (
        <IconButton onClick={onOpenNav}>
          <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" />
        </IconButton>
      )}

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={{ xs: 0.5, sm: 1 }}
        pl={{ lg: 5 }}
      >
        <Box
          sx={{
            display: "flex",
            width: { xs: "218px", sm: "auto" },
            minHeight: "46px",
            gap: 1,
            p: 0.5,
            borderRadius: 4,
            bgcolor: (colorTheme) => alpha(colorTheme.palette.grey[500], 0.04),
            // border: (colorTheme) => `dashed 1px ${colorTheme.palette.divider}`,
          }}
        >
          {isSearch ? <TextField
            value={searchGame}
            onChange={handleSearchGame}
            onKeyDown={handleKeyPress}
            placeholder="Search for game"
            color="primary"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <Iconify
                    icon="ic:round-close"
                    onClick={handleCloseSearch}
                    sx={{
                      cursor: 'pointer',
                      '&:hover': { color: 'text.primary' }
                    }}
                  />
                </InputAdornment>
              )
            }}
            sx={{
              width: '100%',
              '& .MuiInputBase-root': { borderRadius: 4 },
              '& input': { py: 1 },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: "#4471e3 !important"
              }
            }}
          /> : <>
            <MenuButton
              className={pathName === paths.dashboard.casino ? "active" : ""}
              onClick={() => router.push(paths.dashboard.casino)}
            >
              <Iconify icon="ic:casino" mr={isMobile ? 0 : 1} />
              {isMobile ? "" : "Casino"}
            </MenuButton>

            <MenuButton
              className={pathName === paths.dashboard.liveCasino ? "active" : ""}
              onClick={() => router.push(paths.dashboard.liveCasino)}
            >
              <Iconify icon="ri:live-fill" mr={isMobile ? 0 : 1} />
              {isMobile ? "" : "Live Casino"}
            </MenuButton>

            <MenuButton
              onClick={() => { setIsSearch(true) }}
            >
              <Iconify icon="eva:search-fill" mr={isMobile ? 0 : 1} />
              {isMobile ? "" : "Search for game"}
            </MenuButton>
          </>}

        </Box>
      </Stack>

      {/* <SettingsButton /> */}
    </>
  );

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: ((isLoggedIn && name) || theme.palette.mode === 'dark' ? theme.palette.background.default : '#EDEDEE'),
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
          height: HEADER.H_DESKTOP,
          ...(offsetTop && {
            height: HEADER.H_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI + 1}px)`,
          }),
        }),
        backgroundColor: "#ededee"
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
