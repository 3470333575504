// @mui
import { Box, Link, Tooltip, ListItemText } from '@mui/material';
// routes
import { RouterLink } from 'src/routes/components';
import Label from 'src/components/label/label';

import { useDispatch, useSelector } from 'src/store';
import { openDrawer } from 'src/store/reducers/drawer';
//
import Iconify from '../../iconify';
//
import { NavItemProps, NavConfigProps } from '../types';
import { StyledItem, StyledIcon, StyledDotIcon } from './styles';


// ----------------------------------------------------------------------

type Props = NavItemProps & {
  config: NavConfigProps;
};

export default function NavItem({
  item,
  open,
  depth,
  active,
  config,
  externalLink,
  ...other
}: Props) {
  const { title, path, icon, type, text, variant, info, children, disabled, caption, roles } = item;

  const dispatch = useDispatch();

  const { count }: { count: number } = useSelector((state) => state.notification);
  const { name } = useSelector((state) => state.drawer);

  const isActive = name === text;

  const subItem = depth !== 1;

  const renderContent = (
    <StyledItem
      disableGutters
      disabled={disabled}
      active={isActive}
      depth={depth}
      config={config}
      {...other}
    >
      <>
        {icon && <StyledIcon size={config.iconSize}>{icon}</StyledIcon>}

        {subItem && (
          <StyledIcon size={config.iconSize}>
            <StyledDotIcon active={isActive} />
          </StyledIcon>
        )}
      </>

      {!(config.hiddenLabel && !subItem) && (
        <ListItemText
          primary={title}
          secondary={
            caption ? (
              <Tooltip title={caption} placement="top-start">
                <span>{caption}</span>
              </Tooltip>
            ) : null
          }
          primaryTypographyProps={{
            noWrap: true,
            typography: 'body2',
            textTransform: 'capitalize',
            fontWeight: isActive ? 'fontWeightSemiBold' : 'fontWeightMedium',
            color: (isActive ? (theme) => theme.palette.primary.main : ''),
          }}
          secondaryTypographyProps={{
            noWrap: true,
            component: 'span',
            typography: 'caption',
            color: 'text.disabled',
          }}
        />
      )}

      {text === "notifications" && count > 0 && (
        <Box component="span" sx={{ ml: 1, lineHeight: 0 }}>
          <Label color="error">{count}</Label>
        </Box>
      )}

      {!!children && (
        <Iconify
          width={16}
          icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
          sx={{ ml: 1, flexShrink: 0 }}
        />
      )}
    </StyledItem>
  );

  // Hidden item by role
  if (roles && !roles.includes(`${config.currentRole}`)) {
    return null;
  }

  // External link
  // if (externalLink)
  //   return (
  //     <Link
  //       href={path}
  //       target="_blank"
  //       rel="noopener"
  //       underline="none"
  //       color="inherit"
  //       sx={{
  //         ...(disabled && {
  //           cursor: 'default',
  //         }),
  //       }}
  //     >
  //       {renderContent}
  //     </Link>
  //   );

  // Has child
  if (children) {
    return renderContent;
  }

  // Default
  return (
    <>
      {type === "link" ? (
        <Link
          component={RouterLink}
          href={path}
          underline="none"
          color="inherit"
          sx={{
            '& .MuiListItemButton-root': {
              my: 2,
              backgroundColor: (variant === "contained" ? "#4471e3" : "#fff"),
              '& .MuiTypography-root': {
                textAlign: "center",
                color: (variant === "contained" ? "#fff" : "#7a797d")
              },
            },
            '&:hover': {
              '& .MuiListItemButton-root': {
                backgroundColor: "#0641d9",
                '& .MuiTypography-root': {
                  color: "#fff"
                },
              }
            },
            ...(disabled && {
              cursor: 'default',
            }),
          }}
        >
          {renderContent}
        </Link >
      ) : (
        <Link
          // component={RouterLink}
          underline="none"
          color="inherit"
          sx={{
            '& .MuiListItemButton-root': {
              backgroundColor: "#fff",
            },
            '&:hover': {
              '& .MuiListItemButton-root': {
                backgroundColor: "#e6ecfb",
                color: "#4572e6"
              }
            },
            ...(disabled && {
              cursor: 'default',
            }),
          }}
          onClick={() => {
            if (text) dispatch(openDrawer(text));
          }}
        >
          {renderContent}
        </Link>
      )
      }
    </>
  );
}
