export const fNumber = (
    number: number | string,
    disableDecimal = false,
    decimalPlaces = 2
) => {
    const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: disableDecimal ? 0 : decimalPlaces,
        maximumFractionDigits: disableDecimal ? 0 : decimalPlaces,
    });
    return formatter.format(+number);
}
