import { useNavigate } from "react-router-dom";
// @mui
import { Stack, Button } from '@mui/material';
// store
import { useDispatch } from 'src/store';
import { signout } from 'src/store/reducers/auth';

import { PATH_LOGIN } from 'src/config-global';
import Iconify from "src/components/iconify/iconify";

// ----------------------------------------------------------------------

export default function NavFooter() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleUserLogout = () => {
    localStorage.clear();
    dispatch(signout());
    navigate(PATH_LOGIN);
  };

  return (
    <Stack
      sx={{
        px: 2,
        py: 2,
        textAlign: 'center',
      }}
    >
      <Stack alignItems="center">
        <Button
          startIcon={<Iconify icon="uil:signout" width={24} />}
          variant="text"
          color="primary"
          size="large"
          sx={{ width: 1 }}
          onClick={handleUserLogout}
        >
          Sign Out
        </Button>
      </Stack>
    </Stack >
  );
}
