// @mui
import { m } from 'framer-motion';
import { useTheme } from '@mui/material/styles';
import { Box, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// components
import { Logo } from 'src/components/logo';
import { varFade } from 'src/components/animate';
import Carousel, { CarouselDots, useCarousel } from 'src/components/carousel';

// ----------------------------------------------------------------------

type Props = {
  title?: string;
  image?: string;
  children: React.ReactNode;
};

export default function AuthClassicLayout({ children }: Props) {
  const theme = useTheme();

  const carousel = useCarousel({
    autoplay: false,
    speed: 1500,
    ...CarouselDots({
      rounded: true,
      sx: {
        mt: -3,
        color: "#fff",
        '& .slick-active': {
          opacity: "0.9 !important",
        }
      },
    }),
  });

  const mdUp = useResponsive('up', 'md');

  const renderLogo = (
    <Logo
      sx={{
        zIndex: 9,
        position: 'absolute',
        m: { xs: 2, md: 5 },
      }}
    />
  );

  const renderContent = (
    <Stack
      sx={{
        width: 1,
        mx: 'auto',
        maxWidth: 580,
        px: { xs: 2, md: 8 },
        pt: { xs: 15, md: 20 },
        pb: { xs: 15, md: 0 },
      }}
    >
      {children}
    </Stack>
  );

  const renderSection = (
    <Stack
      flexGrow={1}
      spacing={10}
      alignItems="center"
      justifyContent="center"
      width={600}
      sx={{
        position: 'relative',
        '& .slick-slider': {
          width: 1,
          height: 1
        },
        '& .slick-list': {
          height: 1,
          boxShadow: theme.customShadows.z16,
          '& div, span': {
            height: 1
          }
        },
        '& .slider-content': {
          '& img': {
            height: 1
          }
        }
      }}
    >
      <Carousel ref={carousel.carouselRef} {...carousel.carouselSettings}>
        <Grid container sx={{ display: "flex !important", position: "relative", overflow: "hidden", height: 1, background: "linear-gradient(123deg, rgb(50, 108, 247) 10%, rgb(255, 14, 230) 86%)" }}>
          <Grid md={9}>
            <Stack justifyContent="center" alignItems="center">
              <Typography variant="h2" color="white" fontWeight={900}>Welcome to our site!</Typography>
            </Stack>
          </Grid>
          <Grid md={3}>
            <RenderSlides />
          </Grid>
        </Grid>

        {/* <Stack direction="column" sx={{ display: "flex !important", position: "relative", height: 1, backgroundImage: "url(/assets/images/home/slider/banner-lobby-bg.jpg)", backgroundSize: "100% 100%" }}>
          <Stack justifyContent="center" alignItems="center">
            <Box component="img" src="/assets/images/home/slider/banner-lobby-icon.png" height={300} width={300} />
          </Stack>

          <Stack justifyContent="center" alignItems="start">
            <Typography variant="h3" color="white" fontWeight={900}>Welcome Package up to $3000 + 225 FS</Typography>
            <Typography variant="h6" color="hsla(0,0%,100%,.75)">Take advantage of our Welcome Package, and large winnings will be yours to keep!</Typography>
          </Stack>

          <Box component="img" src="/assets/images/home/slider/banner-lobby-bg-right-top.png" sx={{ position: "absolute", width: "40%", top: 0, right: 0 }} />
        </Stack> */}
      </Carousel>
    </Stack>
  );

  return (
    <Stack
      component="main"
      direction="row"
      sx={{
        minHeight: '100vh',
      }}
    >
      {renderLogo}

      {mdUp && renderSection}

      {renderContent}
    </Stack>
  );
}


const RenderSlides = () => {
  const theme = useTheme();

  const isLight = theme.palette.mode === 'light';

  const transition = {
    repeatType: 'loop',
    ease: 'linear',
    duration: 60,
    repeat: Infinity,
  } as const;
  const opacity = 1;

  const images = [
    'https://cdn.cuatroespada.net//images/games_thumb_228m/960/john_hunter_and_the_book_of_tut_respin.jpg',
    'https://cdn.cuatroespada.net//images/games_thumb_228m/960/sugar_rush.svg',
    'https://cdn.cuatroespada.net//images/games_thumb_228m/960/bigger_bass_bonanza.svg',
    'https://cdn.cuatroespada.net//images/games_thumb_228m/925/nitropolis_3.jpg',
    'https://cdn.cuatroespada.net//images/games_thumb_228m/960/starlight_princess.jpg',
    'https://cdn.cuatroespada.net//images/games_thumb_228m/938/fire_in_the_hole_xbomb.svg',
    'https://cdn.cuatroespada.net//images/games_thumb_228m/960/big_bass_splash.jpg',
    'https://cdn.cuatroespada.net//images/games_thumb_228m/992/starburst_sw.jpg',
    'https://cdn.cuatroespada.net//images/games_thumb_228m/954/shining_crown.jpg'
  ];

  return (
    <Stack
      direction="row"
      alignItems="flex-start"
      sx={{
        height: '150%',
        position: 'absolute',
        ml: { xs: 0, md: '50px' },
        opacity: opacity > 0 ? opacity : 0,
        transform: `skew(${-25}deg, ${15}deg)`,
        ...(theme.direction === 'rtl' && {
          transform: `skew(${25}deg, ${15}deg)`,
        }),
      }}
    >
      <Stack
        component={m.div}
        variants={varFade().in}
        sx={{ width: 135, position: 'relative' }}
      >
        <Box
          component={m.div}
          animate={{ y: ['0%', '100%'] }}
          transition={transition}
          sx={{ position: 'absolute', display: 'flex', flexDirection: 'column', gap: 2, height: 'auto !important', mt: 2 }}
        >
          {images.slice(0, 7).map((image) => <Box
            key={image}
            component={m.img}
            alt={isLight ? 'light_1' : 'dark_1'}
            sx={{ width: 1, height: 190, borderRadius: 2 }}
            src={image}
          />)}
        </Box>
        <Box
          component={m.div}
          animate={{ y: ['-100%', '0%'] }}
          transition={transition}
          sx={{ position: 'absolute', display: 'flex', flexDirection: 'column', gap: 2, height: 'auto !important' }}
        >
          {images.slice(0, 7).map((image) => <Box
            key={image}
            component={m.img}
            alt={isLight ? 'light_1' : 'dark_1'}
            sx={{ width: 1, height: 190, borderRadius: 2 }}
            src={image}
          />)}
        </Box>
      </Stack>

      <Stack
        component={m.div}
        variants={varFade().in}
        sx={{ width: 135, position: 'relative', ml: 1, zIndex: 1 }}
      >
        <Box
          component={m.div}
          animate={{ y: ['100%', '0%'] }}
          transition={transition}
          sx={{ position: 'absolute', display: 'flex', flexDirection: 'column', gap: 2, height: 'auto !important', mt: 2 }}
        >
          {images.slice(5, 9).map((image) => <Box
            key={image}
            component={m.img}
            alt={isLight ? 'light_1' : 'dark_1'}
            sx={{ width: 1, height: 190, borderRadius: 2 }}
            src={image}
          />)}
        </Box>
        <Box
          component={m.div}
          animate={{ y: ['0%', '-100%'] }}
          transition={transition}
          sx={{ position: 'absolute', display: 'flex', flexDirection: 'column', gap: 2, height: 'auto !important' }}
        >
          {images.slice(5, 9).map((image) => <Box
            key={image}
            component={m.img}
            alt={isLight ? 'light_1' : 'dark_1'}
            sx={{ width: 1, height: 190, borderRadius: 2 }}
            src={image}
          />)}
        </Box>
      </Stack>

      <Stack
        component={m.div}
        variants={varFade().in}
        sx={{ width: 135, position: 'relative', ml: 1 }}
      >
        <Box
          component={m.div}
          animate={{ y: ['0%', '100%'] }}
          transition={transition}
          sx={{ position: 'absolute', display: 'flex', flexDirection: 'column', gap: 2, height: 'auto !important', mt: 2 }}
        >
          {images.slice(1, 5).map((image) => <Box
            key={image}
            component={m.img}
            alt={isLight ? 'light_1' : 'dark_1'}
            sx={{ width: 1, height: 190, borderRadius: 2 }}
            src={image}
          />)}
        </Box>
        <Box
          component={m.div}
          animate={{ y: ['-100%', '0%'] }}
          transition={transition}
          sx={{ position: 'absolute', display: 'flex', flexDirection: 'column', gap: 2, height: 'auto !important' }}
        >
          {images.slice(1, 5).map((image) => <Box
            key={image}
            component={m.img}
            alt={isLight ? 'light_1' : 'dark_1'}
            sx={{ width: 1, height: 190, borderRadius: 2 }}
            src={image}
          />)}
        </Box>
      </Stack>

      <Stack
        component={m.div}
        variants={varFade().in}
        sx={{ width: 135, position: 'relative', ml: 1, zIndex: 1 }}
      >
        <Box
          component={m.div}
          animate={{ y: ['100%', '0%'] }}
          transition={transition}
          sx={{ position: 'absolute', display: 'flex', flexDirection: 'column', gap: 2, height: 'auto !important', mt: 2 }}
        >
          {images.slice(4, 8).map((image) => <Box
            key={image}
            component={m.img}
            alt={isLight ? 'light_1' : 'dark_1'}
            sx={{ width: 1, height: 190, borderRadius: 2 }}
            src={image}
          />)}
        </Box>
        <Box
          component={m.div}
          animate={{ y: ['0%', '-100%'] }}
          transition={transition}
          sx={{ position: 'absolute', display: 'flex', flexDirection: 'column', gap: 2, height: 'auto !important' }}
        >
          {images.slice(4, 8).map((image) => <Box
            key={image}
            component={m.img}
            alt={isLight ? 'light_1' : 'dark_1'}
            sx={{ width: 1, height: 190, borderRadius: 2 }}
            src={image}
          />)}
        </Box>
      </Stack>
    </Stack>
  )
};