import { forwardRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link, Tooltip, ListItemText } from '@mui/material';
// routes
import { RouterLink } from 'src/routes/components';
import Label from 'src/components/label/label';

import { useDispatch, useSelector } from 'src/store';
import { openDrawer } from 'src/store/reducers/drawer';
import { close } from 'src/store/reducers/casino';
//
import Iconify from '../../iconify';
//
import { NavItemProps, NavConfigProps } from '../types';
import { StyledItem, StyledIcon } from './styles';

// ----------------------------------------------------------------------

type Props = NavItemProps & {
  config: NavConfigProps;
};

const NavItem = forwardRef<HTMLDivElement, Props>(
  ({ item, depth, open, active, externalLink, config, ...other }, ref) => {
    const theme = useTheme();
    const dispatch = useDispatch();

    const { title, path, icon, type, text, children, disabled, caption, roles } = item;

    const { count }: { count: number } = useSelector((state) => state.notification);
    const { name } = useSelector((state) => state.drawer);

    const isActive = name === text;

    const subItem = depth !== 1;

    const renderContent = (
      <StyledItem
        disableGutters
        ref={ref}
        open={open}
        depth={depth}
        active={isActive}
        disabled={disabled}
        config={config}
        {...other}
      >
        {icon && (
          <StyledIcon
            size={config.iconSize}
            sx={{
              ...(subItem && { mr: 1.5 }),
            }}
          >
            {icon}
          </StyledIcon>
        )}

        {!(config.hiddenLabel && !subItem) && (
          <ListItemText
            sx={{
              width: 1,
              flex: 'unset',
              ...(!subItem && {
                px: 0.5,
                mt: 0.5,
              }),
            }}
            primary={title}
            primaryTypographyProps={{
              noWrap: true,
              fontSize: 10,
              lineHeight: '16px',
              textAlign: 'center',
              textTransform: 'capitalize',
              fontWeight: isActive ? 'fontWeightBold' : 'fontWeightSemiBold',
              ...(subItem && {
                textAlign: 'unset',
                fontSize: theme.typography.body2.fontSize,
                lineHeight: theme.typography.body2.lineHeight,
                fontWeight: isActive ? 'fontWeightSemiBold' : 'fontWeightMedium',
              }),
            }}
          />
        )}

        {caption && (
          <Tooltip title={caption} arrow placement="right">
            <Iconify
              width={16}
              icon="eva:info-outline"
              sx={{
                color: 'text.disabled',
                ...(!subItem && {
                  top: 11,
                  left: 6,
                  position: 'absolute',
                }),
              }}
            />
          </Tooltip>
        )}

        {text === "notifications" && count > 0 && (
          <Box component="span" sx={{ position: "absolute", top: 2, right: 2 }}>
            <Label color="error">{count}</Label>
          </Box>
        )}

        {!!children && (
          <Iconify
            width={16}
            icon="eva:arrow-ios-forward-fill"
            sx={{
              top: 11,
              right: 6,
              position: 'absolute',
            }}
          />
        )}
      </StyledItem>
    );

    // Hidden item by role
    if (roles && !roles.includes(`${config.currentRole}`)) {
      return null;
    }

    // External link
    if (externalLink)
      return (
        <Link
          href={path}
          target="_blank"
          rel="noopener"
          underline="none"
          sx={{
            width: 1,
            ...(disabled && {
              cursor: 'default',
            }),
          }}
        >
          {renderContent}
        </Link>
      );

    // Default
    return (
      <>
        {type === "link" ? (
          <Link
            component={RouterLink}
            href={path}
            underline="none"
            sx={{
              width: 1,
              ...(disabled && {
                cursor: 'default',
              }),
            }}
            onClick={() => {
              dispatch(close());
            }}
          >
            {renderContent}
          </Link>
        ) : (
          <Link
            // component={RouterLink}
            underline="none"
            color="inherit"
            sx={{
              ...(disabled && {
                cursor: 'default',
              }),
            }}
            onClick={() => {
              dispatch(close());
              if (text) dispatch(openDrawer(text));
            }}
          >
            {renderContent}
          </Link>
        )}
      </>
    );
  }
);

export default NavItem;
