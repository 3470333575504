import axios from 'src/utils/axios';
import { API_PATH } from "src/config-global";

// ----------------------------------------------------------------------

export const getNotifications = async () => {
  const URL = API_PATH.NOTIFICATION_GET;
  const response = await axios.post(URL, {});
  return response.data;
}

export const checkNotification = async (payload: any) => {
  const URL = API_PATH.NOTIFICATION_CHECK;
  const response = await axios.post(URL, payload);
  return response.data;
}