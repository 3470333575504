import { useEffect, useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Container, Stack, Typography, Divider, Button, Collapse } from '@mui/material';
// components
import { useSettingsContext } from 'src/components/settings';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';

import { useDispatch, useSelector } from 'src/store';
import { openEditPage } from 'src/store/reducers/drawer';

import { getVipInformation } from 'src/api/rewards';

import { UserGroupType } from 'src/types';
// ----------------------------------------------------------------------

const VIPRewardsView = () => {
    const settings = useSettingsContext();

    const [vipData, setVipData] = useState<UserGroupType[]>([]);

    const getVIPData = async () => {
        const response = await getVipInformation();

        if (response.status) {
            setVipData(response.data);
        }
    };

    useEffect(() => {
        getVIPData();
    }, []);

    return (
        <Container maxWidth={settings.themeStretch ? false : 'xl'} sx={{ pb: 2 }}>
            <Stack
                sx={{
                    p: 1,
                    width: 1,
                    mt: { xs: 1, md: 5 }
                }}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography variant="h5" my={1}>VIP Rewards</Typography>
            </Stack>

            <Divider sx={{ mb: 1 }} />

            {vipData.length > 0 && vipData.map((vipDetail: UserGroupType, index: number) =>
                <VipDetailView key={index} data={vipDetail} />
            )}
        </Container >
    );
}

const VipDetailView = ({ data }: { data: UserGroupType }) => {
    const collapse = useBoolean();
    const dispatch = useDispatch();
    const isMobile = useResponsive('down', 'sm');
    const { user } = useSelector((state) => state.auth);
    const playerGroupId = user.groupId || "";

    const openBonusDetail = () => {
        dispatch(openEditPage("bonuses"));
    };

    const renderDivider = (
        <Box sx={{
            backgroundImage: "repeating-linear-gradient(90deg,#ededee,#ededee calc(1rem / 4),transparent calc(1rem / 4),transparent calc(1rem / 2))",
            height: 2,
        }} />
    );

    const renderCollapse = (
        <Collapse
            in={collapse.value}
            timeout="auto"
            unmountOnExit
            sx={{ bgcolor: 'background.neutral' }}
        >
            <Stack sx={{ m: 1.5 }}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    gap={2}
                    height={55}
                >
                    <Stack direction="row" flexGrow={1} gap={1}>
                        <Typography variant='body1' fontWeight={600} width={1}>Min. Deposit Amount</Typography>
                        <Typography variant='body1' fontWeight={600} width={1}>${data.depositAmount}</Typography>
                    </Stack>
                </Stack>

                {renderDivider}

                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    gap={2}
                    height={55}
                >
                    <Stack direction="row" flexGrow={1} gap={1}>
                        <Typography variant='body1' fontWeight={600} width={1}>Min. Wager Amount</Typography>
                        <Typography variant='body1' fontWeight={600} width={1}>${data.wagerAmount}</Typography>
                    </Stack>
                </Stack>

                {renderDivider}

                <Button
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                    disabled={playerGroupId !== data._id}
                    onClick={openBonusDetail}
                >Information</Button>
            </Stack>
        </Collapse>
    );

    return (
        <Stack
            sx={{
                width: 1,
                mt: 1,
                borderRadius: 2,
                border: (theme) => `1px solid ${theme.palette.divider}`,
                boxShadow: (theme) => `${theme.customShadows.z4}`,
                cursor: "pointer",
                overflow: "hidden",
                '&:hover': {
                    border: (theme) => `.125rem solid ${alpha(theme.palette.primary.main, 0.5)}`,
                    boxShadow: (theme) => `0 0 0 .25rem ${alpha(theme.palette.primary.main, 0.1)}`
                }
            }}
        >
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                    width: 1,
                    cursor: "pointer",
                    bgcolor: (theme) => alpha(theme.palette.grey[500], 0.09),
                }}
            >
                <Stack alignItems="center" position="relative">
                    <Box component="img" src="/assets/images/reward/percent.png" height="80px" py={1} />
                </Stack>

                <Stack direction="column" gap={1} flexGrow={1}>
                    <Typography variant='body1' fontWeight={700}>{data.name}</Typography>
                    {/* {!isMobile && <Typography variant='body2'>{`Deposit $${data.depositAmount} or more and wager $${data.wagerAmount} or more`}</Typography>} */}
                </Stack>

                <Stack direction="row" gap={1} mr={1}>
                    <Button
                        variant="text"
                        sx={{
                            fontWeight: "500",
                            '&:hover': { backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.16), color: 'primary.darker' }
                        }}
                        onClick={collapse.onToggle}
                    >{collapse.value ? "Read less" : "Read more"}</Button>

                    {
                        !isMobile && (
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{
                                    fontWeight: "500",
                                    '&:hover': { backgroundColor: (theme) => alpha(theme.palette.primary.main, 1), color: 'white' }
                                }}
                                disabled={playerGroupId !== data._id}
                                onClick={openBonusDetail}
                            >Information</Button>
                        )
                    }
                </Stack>
            </Stack>

            {renderCollapse}
        </Stack>
    )
};

export default VIPRewardsView;