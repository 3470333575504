/* eslint-disable-next-line padded-blocks */
import { combineReducers } from 'redux';

import auth from './auth';
import drawer from './drawer';
import search from './search';
import casino from './casino';
import notification from './notification';

const reducer = combineReducers({
  auth,
  drawer,
  search,
  casino,
  notification
});

export default reducer;
